import React from "react";
import { graphql } from "gatsby";

import styled from "styled-components";
import Link from "../components/helpers/link";
import NoRobots from "../components/helpers/no-robots";
import { slugify } from "../functions/util";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    allPrismicWorkEnt: { edges },
  },
}) {
  return (
    <RichWrap>
      <NoRobots />
      <Content>
        {edges.map(({ node }, i) => (
          <Link key={i} className="h2" to={`/${slugify(node.uid)}`}>
            {node.data.title.text}
          </Link>
        ))}
      </Content>
      <Checkers className="checkers-top" />
      <Checkers className="checkers-right" />
      <Checkers className="checkers-bottom" />
      <Checkers className="checkers-left" />
    </RichWrap>
  );
}

export const Content = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  overflow: hidden;
  text-align: center;
  background: var(--light);
  padding: 4vw 2vw;
`;

const Checkers = styled.span`
  position: absolute;

  /* LEFT */
  &.checkers-left {
    top: 0;
    right: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 4;
  }

  /* RIGHT */
  &.checkers-right {
    top: 0;
    left: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 5;
  }

  /* TOP */
  &.checkers-top {
    top: 0;
    left: 0;
    width: 100%;
    height: var(--pad);
    background: var(--h);
    z-index: 6;
  }

  /* BOTTOM */
  &.checkers-bottom {
    bottom: 0;
    left: 0;
    width: calc(100% + var(--pad));
    height: var(--pad);
    background: var(--h);
    z-index: 5;
  }
`;

const RichWrap = styled.div`
  position: relative;
  padding: var(--pad) var(--pad) 0 0;
  background: var(--light);
  z-index: 5;
  margin-bottom: -8px;
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query SecretIndex {
    allPrismicWorkEnt(
      sort: { fields: data___date, order: DESC }
      filter: { data: { hide_from_lists: { nin: true } } }
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
